/* MARGIN TOP */
.mt-1{ margin-top: 1px !important; }
.mt-2{ margin-top: 2px !important; }
.mt-3{ margin-top: 3px !important; }
.mt-4{ margin-top: 4px !important; }
.mt-5{ margin-top: 5px !important; }
.mt-6{ margin-top: 6px !important; }
.mt-7{ margin-top: 7px !important; }
.mt-8{ margin-top: 8px !important; }
.mt-9{ margin-top: 9px !important; }
.mt-10{ margin-top: 10px !important; }
.mt-11{ margin-top: 11px !important; }
.mt-12{ margin-top: 12px !important; }
.mt-13{ margin-top: 13px !important; }
.mt-14{ margin-top: 14px !important; }
.mt-15{ margin-top: 15px !important; }
.mt-16{ margin-top: 16px !important; }
.mt-17{ margin-top: 17px !important; }
.mt-18{ margin-top: 18px !important; }
.mt-19{ margin-top: 19px !important; }
.mt-20{ margin-top: 20px !important; }
.mt-21{ margin-top: 21px !important; }
.mt-22{ margin-top: 22px !important; }
.mt-23{ margin-top: 23px !important; }
.mt-24{ margin-top: 24px !important; }
.mt-25{ margin-top: 25px !important; }
.mt-26{ margin-top: 26px !important; }
.mt-27{ margin-top: 27px !important; }
.mt-28{ margin-top: 28px !important; }
.mt-29{ margin-top: 29px !important; }
.mt-30{ margin-top: 30px !important; }
.mt-31{ margin-top: 31px !important; }
.mt-32{ margin-top: 32px !important; }
.mt-33{ margin-top: 33px !important; }
.mt-34{ margin-top: 34px !important; }
.mt-35{ margin-top: 35px !important; }
.mt-36{ margin-top: 36px !important; }
.mt-37{ margin-top: 37px !important; }
.mt-38{ margin-top: 38px !important; }
.mt-39{ margin-top: 39px !important; }
.mt-40{ margin-top: 40px !important; }
.mt-41{ margin-top: 41px !important; }
.mt-42{ margin-top: 42px !important; }
.mt-43{ margin-top: 43px !important; }
.mt-44{ margin-top: 44px !important; }
.mt-45{ margin-top: 45px !important; }
.mt-46{ margin-top: 46px !important; }
.mt-47{ margin-top: 47px !important; }
.mt-48{ margin-top: 48px !important; }
.mt-49{ margin-top: 49px !important; }
.mt-50{ margin-top: 50px !important; }
.mt-51{ margin-top: 51px !important; }
.mt-52{ margin-top: 52px !important; }
.mt-53{ margin-top: 53px !important; }
.mt-54{ margin-top: 54px !important; }
.mt-55{ margin-top: 55px !important; }
.mt-56{ margin-top: 56px !important; }
.mt-57{ margin-top: 57px !important; }
.mt-58{ margin-top: 58px !important; }
.mt-59{ margin-top: 59px !important; }
.mt-60{ margin-top: 60px !important; }
.mt-61{ margin-top: 61px !important; }
.mt-62{ margin-top: 62px !important; }
.mt-63{ margin-top: 63px !important; }
.mt-64{ margin-top: 64px !important; }
.mt-65{ margin-top: 65px !important; }
.mt-66{ margin-top: 66px !important; }
.mt-67{ margin-top: 67px !important; }
.mt-68{ margin-top: 68px !important; }
.mt-69{ margin-top: 69px !important; }
.mt-70{ margin-top: 70px !important; }
.mt-71{ margin-top: 71px !important; }
.mt-72{ margin-top: 72px !important; }
.mt-73{ margin-top: 73px !important; }
.mt-74{ margin-top: 74px !important; }
.mt-75{ margin-top: 75px !important; }
.mt-76{ margin-top: 76px !important; }
.mt-77{ margin-top: 77px !important; }
.mt-78{ margin-top: 78px !important; }
.mt-79{ margin-top: 79px !important; }
.mt-80{ margin-top: 80px !important; }
.mt-81{ margin-top: 81px !important; }
.mt-82{ margin-top: 82px !important; }
.mt-83{ margin-top: 83px !important; }
.mt-84{ margin-top: 84px !important; }
.mt-85{ margin-top: 85px !important; }
.mt-86{ margin-top: 86px !important; }
.mt-87{ margin-top: 87px !important; }
.mt-88{ margin-top: 88px !important; }
.mt-89{ margin-top: 89px !important; }
.mt-90{ margin-top: 90px !important; }
.mt-91{ margin-top: 91px !important; }
.mt-92{ margin-top: 92px !important; }
.mt-93{ margin-top: 93px !important; }
.mt-94{ margin-top: 94px !important; }
.mt-95{ margin-top: 95px !important; }
.mt-96{ margin-top: 96px !important; }
.mt-97{ margin-top: 97px !important; }
.mt-98{ margin-top: 98px !important; }
.mt-99{ margin-top: 99px !important; }
.mt-100{ margin-top: 100px !important; }

.mt-3p{ margin-top: 3% !important; }
.mt-5p{ margin-top: 5% !important; }
.mt-7p{ margin-top: 7% !important; }
.mt-10p{ margin-top: 10% !important; }
.mt-15p{ margin-top: 15% !important; }
.mt-20p{ margin-top: 20% !important; }
.mt-25p{ margin-top: 25% !important; }
.mt-30p{ margin-top: 30% !important; }
.mt-35p{ margin-top: 35% !important; }
.mt-40p{ margin-top: 40% !important; }
.mt-45p{ margin-top: 45% !important; }
.mt-50p{ margin-top: 50% !important; }
/* MARGIN TOP */

/* MARGIN BOTTOM */
.mb-1{ margin-bottom: 1px !important; }
.mb-2{ margin-bottom: 2px !important; }
.mb-3{ margin-bottom: 3px !important; }
.mb-4{ margin-bottom: 4px !important; }
.mb-5{ margin-bottom: 5px !important; }
.mb-6{ margin-bottom: 6px !important; }
.mb-7{ margin-bottom: 7px !important; }
.mb-8{ margin-bottom: 8px !important; }
.mb-9{ margin-bottom: 9px !important; }
.mb-10{ margin-bottom: 10px !important; }
.mb-11{ margin-bottom: 11px !important; }
.mb-12{ margin-bottom: 12px !important; }
.mb-13{ margin-bottom: 13px !important; }
.mb-14{ margin-bottom: 14px !important; }
.mb-15{ margin-bottom: 15px !important; }
.mb-16{ margin-bottom: 16px !important; }
.mb-17{ margin-bottom: 17px !important; }
.mb-18{ margin-bottom: 18px !important; }
.mb-19{ margin-bottom: 19px !important; }
.mb-20{ margin-bottom: 20px !important; }
.mb-21{ margin-bottom: 21px !important; }
.mb-22{ margin-bottom: 22px !important; }
.mb-23{ margin-bottom: 23px !important; }
.mb-24{ margin-bottom: 24px !important; }
.mb-25{ margin-bottom: 25px !important; }
.mb-26{ margin-bottom: 26px !important; }
.mb-27{ margin-bottom: 27px !important; }
.mb-28{ margin-bottom: 28px !important; }
.mb-29{ margin-bottom: 29px !important; }
.mb-30{ margin-bottom: 30px !important; }
.mb-31{ margin-bottom: 31px !important; }
.mb-32{ margin-bottom: 32px !important; }
.mb-33{ margin-bottom: 33px !important; }
.mb-34{ margin-bottom: 34px !important; }
.mb-35{ margin-bottom: 35px !important; }
.mb-36{ margin-bottom: 36px !important; }
.mb-37{ margin-bottom: 37px !important; }
.mb-38{ margin-bottom: 38px !important; }
.mb-39{ margin-bottom: 39px !important; }
.mb-40{ margin-bottom: 40px !important; }
.mb-41{ margin-bottom: 41px !important; }
.mb-42{ margin-bottom: 42px !important; }
.mb-43{ margin-bottom: 43px !important; }
.mb-44{ margin-bottom: 44px !important; }
.mb-45{ margin-bottom: 45px !important; }
.mb-46{ margin-bottom: 46px !important; }
.mb-47{ margin-bottom: 47px !important; }
.mb-48{ margin-bottom: 48px !important; }
.mb-49{ margin-bottom: 49px !important; }
.mb-50{ margin-bottom: 50px !important; }
.mb-51{ margin-bottom: 51px !important; }
.mb-52{ margin-bottom: 52px !important; }
.mb-53{ margin-bottom: 53px !important; }
.mb-54{ margin-bottom: 54px !important; }
.mb-55{ margin-bottom: 55px !important; }
.mb-56{ margin-bottom: 56px !important; }
.mb-57{ margin-bottom: 57px !important; }
.mb-58{ margin-bottom: 58px !important; }
.mb-59{ margin-bottom: 59px !important; }
.mb-60{ margin-bottom: 60px !important; }
.mb-61{ margin-bottom: 61px !important; }
.mb-62{ margin-bottom: 62px !important; }
.mb-63{ margin-bottom: 63px !important; }
.mb-64{ margin-bottom: 64px !important; }
.mb-65{ margin-bottom: 65px !important; }
.mb-66{ margin-bottom: 66px !important; }
.mb-67{ margin-bottom: 67px !important; }
.mb-68{ margin-bottom: 68px !important; }
.mb-69{ margin-bottom: 69px !important; }
.mb-70{ margin-bottom: 70px !important; }
.mb-71{ margin-bottom: 71px !important; }
.mb-72{ margin-bottom: 72px !important; }
.mb-73{ margin-bottom: 73px !important; }
.mb-74{ margin-bottom: 74px !important; }
.mb-75{ margin-bottom: 75px !important; }
.mb-76{ margin-bottom: 76px !important; }
.mb-77{ margin-bottom: 77px !important; }
.mb-78{ margin-bottom: 78px !important; }
.mb-79{ margin-bottom: 79px !important; }
.mb-80{ margin-bottom: 80px !important; }
.mb-81{ margin-bottom: 81px !important; }
.mb-82{ margin-bottom: 82px !important; }
.mb-83{ margin-bottom: 83px !important; }
.mb-84{ margin-bottom: 84px !important; }
.mb-85{ margin-bottom: 85px !important; }
.mb-86{ margin-bottom: 86px !important; }
.mb-87{ margin-bottom: 87px !important; }
.mb-88{ margin-bottom: 88px !important; }
.mb-89{ margin-bottom: 89px !important; }
.mb-90{ margin-bottom: 90px !important; }
.mb-91{ margin-bottom: 91px !important; }
.mb-92{ margin-bottom: 92px !important; }
.mb-93{ margin-bottom: 93px !important; }
.mb-94{ margin-bottom: 94px !important; }
.mb-95{ margin-bottom: 95px !important; }
.mb-96{ margin-bottom: 96px !important; }
.mb-97{ margin-bottom: 97px !important; }
.mb-98{ margin-bottom: 98px !important; }
.mb-99{ margin-bottom: 99px !important; }
.mb-100{ margin-bottom: 100px !important; }

.mb-5p{ margin-bottom: 5% !important; }
.mb-10p{ margin-bottom: 10% !important; }
.mb-15p{ margin-bottom: 15% !important; }
.mb-20p{ margin-bottom: 20% !important; }
.mb-25p{ margin-bottom: 25% !important; }
.mb-30p{ margin-bottom: 30% !important; }
.mb-35p{ margin-bottom: 35% !important; }
.mb-40p{ margin-bottom: 40% !important; }
.mb-45p{ margin-bottom: 45% !important; }
.mb-50p{ margin-bottom: 50% !important; }
/* MARGIN BOTTOM */

/* MARGIN RIGHT */
.mr-1{ margin-right: 1px !important; }
.mr-2{ margin-right: 2px !important; }
.mr-3{ margin-right: 3px !important; }
.mr-4{ margin-right: 4px !important; }
.mr-5{ margin-right: 5px !important; }
.mr-6{ margin-right: 6px !important; }
.mr-7{ margin-right: 7px !important; }
.mr-8{ margin-right: 8px !important; }
.mr-9{ margin-right: 9px !important; }
.mr-10{ margin-right: 10px !important; }
.mr-11{ margin-right: 11px !important; }
.mr-12{ margin-right: 12px !important; }
.mr-13{ margin-right: 13px !important; }
.mr-14{ margin-right: 14px !important; }
.mr-15{ margin-right: 15px !important; }
.mr-16{ margin-right: 16px !important; }
.mr-17{ margin-right: 17px !important; }
.mr-18{ margin-right: 18px !important; }
.mr-19{ margin-right: 19px !important; }
.mr-20{ margin-right: 20px !important; }
.mr-21{ margin-right: 21px !important; }
.mr-22{ margin-right: 22px !important; }
.mr-23{ margin-right: 23px !important; }
.mr-24{ margin-right: 24px !important; }
.mr-25{ margin-right: 25px !important; }
.mr-26{ margin-right: 26px !important; }
.mr-27{ margin-right: 27px !important; }
.mr-28{ margin-right: 28px !important; }
.mr-29{ margin-right: 29px !important; }
.mr-30{ margin-right: 30px !important; }
.mr-31{ margin-right: 31px !important; }
.mr-32{ margin-right: 32px !important; }
.mr-33{ margin-right: 33px !important; }
.mr-34{ margin-right: 34px !important; }
.mr-35{ margin-right: 35px !important; }
.mr-36{ margin-right: 36px !important; }
.mr-37{ margin-right: 37px !important; }
.mr-38{ margin-right: 38px !important; }
.mr-39{ margin-right: 39px !important; }
.mr-40{ margin-right: 40px !important; }
.mr-41{ margin-right: 41px !important; }
.mr-42{ margin-right: 42px !important; }
.mr-43{ margin-right: 43px !important; }
.mr-44{ margin-right: 44px !important; }
.mr-45{ margin-right: 45px !important; }
.mr-46{ margin-right: 46px !important; }
.mr-47{ margin-right: 47px !important; }
.mr-48{ margin-right: 48px !important; }
.mr-49{ margin-right: 49px !important; }
.mr-50{ margin-right: 50px !important; }
.mr-51{ margin-right: 51px !important; }
.mr-52{ margin-right: 52px !important; }
.mr-53{ margin-right: 53px !important; }
.mr-54{ margin-right: 54px !important; }
.mr-55{ margin-right: 55px !important; }
.mr-56{ margin-right: 56px !important; }
.mr-57{ margin-right: 57px !important; }
.mr-58{ margin-right: 58px !important; }
.mr-59{ margin-right: 59px !important; }
.mr-60{ margin-right: 60px !important; }
.mr-61{ margin-right: 61px !important; }
.mr-62{ margin-right: 62px !important; }
.mr-63{ margin-right: 63px !important; }
.mr-64{ margin-right: 64px !important; }
.mr-65{ margin-right: 65px !important; }
.mr-66{ margin-right: 66px !important; }
.mr-67{ margin-right: 67px !important; }
.mr-68{ margin-right: 68px !important; }
.mr-69{ margin-right: 69px !important; }
.mr-70{ margin-right: 70px !important; }
.mr-71{ margin-right: 71px !important; }
.mr-72{ margin-right: 72px !important; }
.mr-73{ margin-right: 73px !important; }
.mr-74{ margin-right: 74px !important; }
.mr-75{ margin-right: 75px !important; }
.mr-76{ margin-right: 76px !important; }
.mr-77{ margin-right: 77px !important; }
.mr-78{ margin-right: 78px !important; }
.mr-79{ margin-right: 79px !important; }
.mr-80{ margin-right: 80px !important; }
.mr-81{ margin-right: 81px !important; }
.mr-82{ margin-right: 82px !important; }
.mr-83{ margin-right: 83px !important; }
.mr-84{ margin-right: 84px !important; }
.mr-85{ margin-right: 85px !important; }
.mr-86{ margin-right: 86px !important; }
.mr-87{ margin-right: 87px !important; }
.mr-88{ margin-right: 88px !important; }
.mr-89{ margin-right: 89px !important; }
.mr-90{ margin-right: 90px !important; }
.mr-91{ margin-right: 91px !important; }
.mr-92{ margin-right: 92px !important; }
.mr-93{ margin-right: 93px !important; }
.mr-94{ margin-right: 94px !important; }
.mr-95{ margin-right: 95px !important; }
.mr-96{ margin-right: 96px !important; }
.mr-97{ margin-right: 97px !important; }
.mr-98{ margin-right: 98px !important; }
.mr-99{ margin-right: 99px !important; }
.mr-100{ margin-right: 100px !important; }

.mr-2p{ margin-right: 2% !important; }
.mr-5p{ margin-right: 5% !important; }
.mr-10p{ margin-right: 10% !important; }
.mr-15p{ margin-right: 15% !important; }
.mr-20p{ margin-right: 20% !important; }
.mr-25p{ margin-right: 25% !important; }
.mr-30p{ margin-right: 30% !important; }
.mr-35p{ margin-right: 35% !important; }
.mr-40p{ margin-right: 40% !important; }
.mr-45p{ margin-right: 45% !important; }
.mr-50p{ margin-right: 50% !important; }
/* MARGIN RIGHT */

/* MARGIN LEFT */
.ml-1{ margin-left: 1px !important; }
.ml-2{ margin-left: 2px !important; }
.ml-3{ margin-left: 3px !important; }
.ml-4{ margin-left: 4px !important; }
.ml-5{ margin-left: 5px !important; }
.ml-6{ margin-left: 6px !important; }
.ml-7{ margin-left: 7px !important; }
.ml-8{ margin-left: 8px !important; }
.ml-9{ margin-left: 9px !important; }
.ml-10{ margin-left: 10px !important; }
.ml-11{ margin-left: 11px !important; }
.ml-12{ margin-left: 12px !important; }
.ml-13{ margin-left: 13px !important; }
.ml-14{ margin-left: 14px !important; }
.ml-15{ margin-left: 15px !important; }
.ml-16{ margin-left: 16px !important; }
.ml-17{ margin-left: 17px !important; }
.ml-18{ margin-left: 18px !important; }
.ml-19{ margin-left: 19px !important; }
.ml-20{ margin-left: 20px !important; }
.ml-21{ margin-left: 21px !important; }
.ml-22{ margin-left: 22px !important; }
.ml-23{ margin-left: 23px !important; }
.ml-24{ margin-left: 24px !important; }
.ml-25{ margin-left: 25px !important; }
.ml-26{ margin-left: 26px !important; }
.ml-27{ margin-left: 27px !important; }
.ml-28{ margin-left: 28px !important; }
.ml-29{ margin-left: 29px !important; }
.ml-30{ margin-left: 30px !important; }
.ml-31{ margin-left: 31px !important; }
.ml-32{ margin-left: 32px !important; }
.ml-33{ margin-left: 33px !important; }
.ml-34{ margin-left: 34px !important; }
.ml-35{ margin-left: 35px !important; }
.ml-36{ margin-left: 36px !important; }
.ml-37{ margin-left: 37px !important; }
.ml-38{ margin-left: 38px !important; }
.ml-39{ margin-left: 39px !important; }
.ml-40{ margin-left: 40px !important; }
.ml-41{ margin-left: 41px !important; }
.ml-42{ margin-left: 42px !important; }
.ml-43{ margin-left: 43px !important; }
.ml-44{ margin-left: 44px !important; }
.ml-45{ margin-left: 45px !important; }
.ml-46{ margin-left: 46px !important; }
.ml-47{ margin-left: 47px !important; }
.ml-48{ margin-left: 48px !important; }
.ml-49{ margin-left: 49px !important; }
.ml-50{ margin-left: 50px !important; }
.ml-51{ margin-left: 51px !important; }
.ml-52{ margin-left: 52px !important; }
.ml-53{ margin-left: 53px !important; }
.ml-54{ margin-left: 54px !important; }
.ml-55{ margin-left: 55px !important; }
.ml-56{ margin-left: 56px !important; }
.ml-57{ margin-left: 57px !important; }
.ml-58{ margin-left: 58px !important; }
.ml-59{ margin-left: 59px !important; }
.ml-60{ margin-left: 60px !important; }
.ml-61{ margin-left: 61px !important; }
.ml-62{ margin-left: 62px !important; }
.ml-63{ margin-left: 63px !important; }
.ml-64{ margin-left: 64px !important; }
.ml-65{ margin-left: 65px !important; }
.ml-66{ margin-left: 66px !important; }
.ml-67{ margin-left: 67px !important; }
.ml-68{ margin-left: 68px !important; }
.ml-69{ margin-left: 69px !important; }
.ml-70{ margin-left: 70px !important; }
.ml-71{ margin-left: 71px !important; }
.ml-72{ margin-left: 72px !important; }
.ml-73{ margin-left: 73px !important; }
.ml-74{ margin-left: 74px !important; }
.ml-75{ margin-left: 75px !important; }
.ml-76{ margin-left: 76px !important; }
.ml-77{ margin-left: 77px !important; }
.ml-78{ margin-left: 78px !important; }
.ml-79{ margin-left: 79px !important; }
.ml-80{ margin-left: 80px !important; }
.ml-81{ margin-left: 81px !important; }
.ml-82{ margin-left: 82px !important; }
.ml-83{ margin-left: 83px !important; }
.ml-84{ margin-left: 84px !important; }
.ml-85{ margin-left: 85px !important; }
.ml-86{ margin-left: 86px !important; }
.ml-87{ margin-left: 87px !important; }
.ml-88{ margin-left: 88px !important; }
.ml-89{ margin-left: 89px !important; }
.ml-90{ margin-left: 90px !important; }
.ml-91{ margin-left: 91px !important; }
.ml-92{ margin-left: 92px !important; }
.ml-93{ margin-left: 93px !important; }
.ml-94{ margin-left: 94px !important; }
.ml-95{ margin-left: 95px !important; }
.ml-96{ margin-left: 96px !important; }
.ml-97{ margin-left: 97px !important; }
.ml-98{ margin-left: 98px !important; }
.ml-99{ margin-left: 99px !important; }
.ml-100{ margin-left: 100px !important; }

.ml-2p{ margin-left: 2% !important; }
.ml-5p{ margin-left: 5% !important; }
.ml-10p{ margin-left: 10% !important; }
.ml-15p{ margin-left: 15% !important; }
.ml-20p{ margin-left: 20% !important; }
.ml-25p{ margin-left: 25% !important; }
.ml-30p{ margin-left: 30% !important; }
.ml-35p{ margin-left: 35% !important; }
.ml-40p{ margin-left: 40% !important; }
.ml-45p{ margin-left: 45% !important; }
.ml-50p{ margin-left: 50% !important; }
/* MARGIN LEFT */

/* PADDING TOP */
.pt-1{ padding-top: 1px !important; }
.pt-2{ padding-top: 2px !important; }
.pt-3{ padding-top: 3px !important; }
.pt-4{ padding-top: 4px !important; }
.pt-5{ padding-top: 5px !important; }
.pt-6{ padding-top: 6px !important; }
.pt-7{ padding-top: 7px !important; }
.pt-8{ padding-top: 8px !important; }
.pt-9{ padding-top: 9px !important; }
.pt-10{ padding-top: 10px !important; }
.pt-11{ padding-top: 11px !important; }
.pt-12{ padding-top: 12px !important; }
.pt-13{ padding-top: 13px !important; }
.pt-14{ padding-top: 14px !important; }
.pt-15{ padding-top: 15px !important; }
.pt-16{ padding-top: 16px !important; }
.pt-17{ padding-top: 17px !important; }
.pt-18{ padding-top: 18px !important; }
.pt-19{ padding-top: 19px !important; }
.pt-20{ padding-top: 20px !important; }
.pt-21{ padding-top: 21px !important; }
.pt-22{ padding-top: 22px !important; }
.pt-23{ padding-top: 23px !important; }
.pt-24{ padding-top: 24px !important; }
.pt-25{ padding-top: 25px !important; }
.pt-26{ padding-top: 26px !important; }
.pt-27{ padding-top: 27px !important; }
.pt-28{ padding-top: 28px !important; }
.pt-29{ padding-top: 29px !important; }
.pt-30{ padding-top: 30px !important; }
.pt-31{ padding-top: 31px !important; }
.pt-32{ padding-top: 32px !important; }
.pt-33{ padding-top: 33px !important; }
.pt-34{ padding-top: 34px !important; }
.pt-35{ padding-top: 35px !important; }
.pt-36{ padding-top: 36px !important; }
.pt-37{ padding-top: 37px !important; }
.pt-38{ padding-top: 38px !important; }
.pt-39{ padding-top: 39px !important; }
.pt-40{ padding-top: 40px !important; }
.pt-41{ padding-top: 41px !important; }
.pt-42{ padding-top: 42px !important; }
.pt-43{ padding-top: 43px !important; }
.pt-44{ padding-top: 44px !important; }
.pt-45{ padding-top: 45px !important; }
.pt-46{ padding-top: 46px !important; }
.pt-47{ padding-top: 47px !important; }
.pt-48{ padding-top: 48px !important; }
.pt-49{ padding-top: 49px !important; }
.pt-50{ padding-top: 50px !important; }
.pt-51{ padding-top: 51px !important; }
.pt-52{ padding-top: 52px !important; }
.pt-53{ padding-top: 53px !important; }
.pt-54{ padding-top: 54px !important; }
.pt-55{ padding-top: 55px !important; }
.pt-56{ padding-top: 56px !important; }
.pt-57{ padding-top: 57px !important; }
.pt-58{ padding-top: 58px !important; }
.pt-59{ padding-top: 59px !important; }
.pt-60{ padding-top: 60px !important; }
.pt-61{ padding-top: 61px !important; }
.pt-62{ padding-top: 62px !important; }
.pt-63{ padding-top: 63px !important; }
.pt-64{ padding-top: 64px !important; }
.pt-65{ padding-top: 65px !important; }
.pt-66{ padding-top: 66px !important; }
.pt-67{ padding-top: 67px !important; }
.pt-68{ padding-top: 68px !important; }
.pt-69{ padding-top: 69px !important; }
.pt-70{ padding-top: 70px !important; }
.pt-71{ padding-top: 71px !important; }
.pt-72{ padding-top: 72px !important; }
.pt-73{ padding-top: 73px !important; }
.pt-74{ padding-top: 74px !important; }
.pt-75{ padding-top: 75px !important; }
.pt-76{ padding-top: 76px !important; }
.pt-77{ padding-top: 77px !important; }
.pt-78{ padding-top: 78px !important; }
.pt-79{ padding-top: 79px !important; }
.pt-80{ padding-top: 80px !important; }
.pt-81{ padding-top: 81px !important; }
.pt-82{ padding-top: 82px !important; }
.pt-83{ padding-top: 83px !important; }
.pt-84{ padding-top: 84px !important; }
.pt-85{ padding-top: 85px !important; }
.pt-86{ padding-top: 86px !important; }
.pt-87{ padding-top: 87px !important; }
.pt-88{ padding-top: 88px !important; }
.pt-89{ padding-top: 89px !important; }
.pt-90{ padding-top: 90px !important; }
.pt-91{ padding-top: 91px !important; }
.pt-92{ padding-top: 92px !important; }
.pt-93{ padding-top: 93px !important; }
.pt-94{ padding-top: 94px !important; }
.pt-95{ padding-top: 95px !important; }
.pt-96{ padding-top: 96px !important; }
.pt-97{ padding-top: 97px !important; }
.pt-98{ padding-top: 98px !important; }
.pt-99{ padding-top: 99px !important; }
.pt-100{ padding-top: 100px !important; }

.pt-5p{ padding-top: 5% !important; }
.pt-10p{ padding-top: 10% !important; }
.pt-15p{ padding-top: 15% !important; }
.pt-20p{ padding-top: 20% !important; }
.pt-25p{ padding-top: 25% !important; }
.pt-30p{ padding-top: 30% !important; }
.pt-35p{ padding-top: 35% !important; }
.pt-40p{ padding-top: 40% !important; }
.pt-45p{ padding-top: 45% !important; }
.pt-50p{ padding-top: 50% !important; }
/* PADDING TOP */

/* PADDING BOTTOM */
.pb-1{ padding-bottom: 1px !important; }
.pb-2{ padding-bottom: 2px !important; }
.pb-3{ padding-bottom: 3px !important; }
.pb-4{ padding-bottom: 4px !important; }
.pb-5{ padding-bottom: 5px !important; }
.pb-6{ padding-bottom: 6px !important; }
.pb-7{ padding-bottom: 7px !important; }
.pb-8{ padding-bottom: 8px !important; }
.pb-9{ padding-bottom: 9px !important; }
.pb-10{ padding-bottom: 10px !important; }
.pb-11{ padding-bottom: 11px !important; }
.pb-12{ padding-bottom: 12px !important; }
.pb-13{ padding-bottom: 13px !important; }
.pb-14{ padding-bottom: 14px !important; }
.pb-15{ padding-bottom: 15px !important; }
.pb-16{ padding-bottom: 16px !important; }
.pb-17{ padding-bottom: 17px !important; }
.pb-18{ padding-bottom: 18px !important; }
.pb-19{ padding-bottom: 19px !important; }
.pb-20{ padding-bottom: 20px !important; }
.pb-21{ padding-bottom: 21px !important; }
.pb-22{ padding-bottom: 22px !important; }
.pb-23{ padding-bottom: 23px !important; }
.pb-24{ padding-bottom: 24px !important; }
.pb-25{ padding-bottom: 25px !important; }
.pb-26{ padding-bottom: 26px !important; }
.pb-27{ padding-bottom: 27px !important; }
.pb-28{ padding-bottom: 28px !important; }
.pb-29{ padding-bottom: 29px !important; }
.pb-30{ padding-bottom: 30px !important; }
.pb-31{ padding-bottom: 31px !important; }
.pb-32{ padding-bottom: 32px !important; }
.pb-33{ padding-bottom: 33px !important; }
.pb-34{ padding-bottom: 34px !important; }
.pb-35{ padding-bottom: 35px !important; }
.pb-36{ padding-bottom: 36px !important; }
.pb-37{ padding-bottom: 37px !important; }
.pb-38{ padding-bottom: 38px !important; }
.pb-39{ padding-bottom: 39px !important; }
.pb-40{ padding-bottom: 40px !important; }
.pb-41{ padding-bottom: 41px !important; }
.pb-42{ padding-bottom: 42px !important; }
.pb-43{ padding-bottom: 43px !important; }
.pb-44{ padding-bottom: 44px !important; }
.pb-45{ padding-bottom: 45px !important; }
.pb-46{ padding-bottom: 46px !important; }
.pb-47{ padding-bottom: 47px !important; }
.pb-48{ padding-bottom: 48px !important; }
.pb-49{ padding-bottom: 49px !important; }
.pb-50{ padding-bottom: 50px !important; }
.pb-51{ padding-bottom: 51px !important; }
.pb-52{ padding-bottom: 52px !important; }
.pb-53{ padding-bottom: 53px !important; }
.pb-54{ padding-bottom: 54px !important; }
.pb-55{ padding-bottom: 55px !important; }
.pb-56{ padding-bottom: 56px !important; }
.pb-57{ padding-bottom: 57px !important; }
.pb-58{ padding-bottom: 58px !important; }
.pb-59{ padding-bottom: 59px !important; }
.pb-60{ padding-bottom: 60px !important; }
.pb-61{ padding-bottom: 61px !important; }
.pb-62{ padding-bottom: 62px !important; }
.pb-63{ padding-bottom: 63px !important; }
.pb-64{ padding-bottom: 64px !important; }
.pb-65{ padding-bottom: 65px !important; }
.pb-66{ padding-bottom: 66px !important; }
.pb-67{ padding-bottom: 67px !important; }
.pb-68{ padding-bottom: 68px !important; }
.pb-69{ padding-bottom: 69px !important; }
.pb-70{ padding-bottom: 70px !important; }
.pb-71{ padding-bottom: 71px !important; }
.pb-72{ padding-bottom: 72px !important; }
.pb-73{ padding-bottom: 73px !important; }
.pb-74{ padding-bottom: 74px !important; }
.pb-75{ padding-bottom: 75px !important; }
.pb-76{ padding-bottom: 76px !important; }
.pb-77{ padding-bottom: 77px !important; }
.pb-78{ padding-bottom: 78px !important; }
.pb-79{ padding-bottom: 79px !important; }
.pb-80{ padding-bottom: 80px !important; }
.pb-81{ padding-bottom: 81px !important; }
.pb-82{ padding-bottom: 82px !important; }
.pb-83{ padding-bottom: 83px !important; }
.pb-84{ padding-bottom: 84px !important; }
.pb-85{ padding-bottom: 85px !important; }
.pb-86{ padding-bottom: 86px !important; }
.pb-87{ padding-bottom: 87px !important; }
.pb-88{ padding-bottom: 88px !important; }
.pb-89{ padding-bottom: 89px !important; }
.pb-90{ padding-bottom: 90px !important; }
.pb-91{ padding-bottom: 91px !important; }
.pb-92{ padding-bottom: 92px !important; }
.pb-93{ padding-bottom: 93px !important; }
.pb-94{ padding-bottom: 94px !important; }
.pb-95{ padding-bottom: 95px !important; }
.pb-96{ padding-bottom: 96px !important; }
.pb-97{ padding-bottom: 97px !important; }
.pb-98{ padding-bottom: 98px !important; }
.pb-99{ padding-bottom: 99px !important; }
.pb-100{ padding-bottom: 100px !important; }

.pb-5p{ padding-bottom: 5% !important; }
.pb-10p{ padding-bottom: 10% !important; }
.pb-15p{ padding-bottom: 15% !important; }
.pb-20p{ padding-bottom: 20% !important; }
.pb-25p{ padding-bottom: 25% !important; }
.pb-30p{ padding-bottom: 30% !important; }
.pb-35p{ padding-bottom: 35% !important; }
.pb-40p{ padding-bottom: 40% !important; }
.pb-45p{ padding-bottom: 45% !important; }
.pb-50p{ padding-bottom: 50% !important; }
/* PADDING BOTTOM */

/* PADDING RIGHT */
.pr-1{ padding-right: 1px !important; }
.pr-2{ padding-right: 2px !important; }
.pr-3{ padding-right: 3px !important; }
.pr-4{ padding-right: 4px !important; }
.pr-5{ padding-right: 5px !important; }
.pr-6{ padding-right: 6px !important; }
.pr-7{ padding-right: 7px !important; }
.pr-8{ padding-right: 8px !important; }
.pr-9{ padding-right: 9px !important; }
.pr-10{ padding-right: 10px !important; }
.pr-11{ padding-right: 11px !important; }
.pr-12{ padding-right: 12px !important; }
.pr-13{ padding-right: 13px !important; }
.pr-14{ padding-right: 14px !important; }
.pr-15{ padding-right: 15px !important; }
.pr-16{ padding-right: 16px !important; }
.pr-17{ padding-right: 17px !important; }
.pr-18{ padding-right: 18px !important; }
.pr-19{ padding-right: 19px !important; }
.pr-20{ padding-right: 20px !important; }
.pr-21{ padding-right: 21px !important; }
.pr-22{ padding-right: 22px !important; }
.pr-23{ padding-right: 23px !important; }
.pr-24{ padding-right: 24px !important; }
.pr-25{ padding-right: 25px !important; }
.pr-26{ padding-right: 26px !important; }
.pr-27{ padding-right: 27px !important; }
.pr-28{ padding-right: 28px !important; }
.pr-29{ padding-right: 29px !important; }
.pr-30{ padding-right: 30px !important; }
.pr-31{ padding-right: 31px !important; }
.pr-32{ padding-right: 32px !important; }
.pr-33{ padding-right: 33px !important; }
.pr-34{ padding-right: 34px !important; }
.pr-35{ padding-right: 35px !important; }
.pr-36{ padding-right: 36px !important; }
.pr-37{ padding-right: 37px !important; }
.pr-38{ padding-right: 38px !important; }
.pr-39{ padding-right: 39px !important; }
.pr-40{ padding-right: 40px !important; }
.pr-41{ padding-right: 41px !important; }
.pr-42{ padding-right: 42px !important; }
.pr-43{ padding-right: 43px !important; }
.pr-44{ padding-right: 44px !important; }
.pr-45{ padding-right: 45px !important; }
.pr-46{ padding-right: 46px !important; }
.pr-47{ padding-right: 47px !important; }
.pr-48{ padding-right: 48px !important; }
.pr-49{ padding-right: 49px !important; }
.pr-50{ padding-right: 50px !important; }
.pr-51{ padding-right: 51px !important; }
.pr-52{ padding-right: 52px !important; }
.pr-53{ padding-right: 53px !important; }
.pr-54{ padding-right: 54px !important; }
.pr-55{ padding-right: 55px !important; }
.pr-56{ padding-right: 56px !important; }
.pr-57{ padding-right: 57px !important; }
.pr-58{ padding-right: 58px !important; }
.pr-59{ padding-right: 59px !important; }
.pr-60{ padding-right: 60px !important; }
.pr-61{ padding-right: 61px !important; }
.pr-62{ padding-right: 62px !important; }
.pr-63{ padding-right: 63px !important; }
.pr-64{ padding-right: 64px !important; }
.pr-65{ padding-right: 65px !important; }
.pr-66{ padding-right: 66px !important; }
.pr-67{ padding-right: 67px !important; }
.pr-68{ padding-right: 68px !important; }
.pr-69{ padding-right: 69px !important; }
.pr-70{ padding-right: 70px !important; }
.pr-71{ padding-right: 71px !important; }
.pr-72{ padding-right: 72px !important; }
.pr-73{ padding-right: 73px !important; }
.pr-74{ padding-right: 74px !important; }
.pr-75{ padding-right: 75px !important; }
.pr-76{ padding-right: 76px !important; }
.pr-77{ padding-right: 77px !important; }
.pr-78{ padding-right: 78px !important; }
.pr-79{ padding-right: 79px !important; }
.pr-80{ padding-right: 80px !important; }
.pr-81{ padding-right: 81px !important; }
.pr-82{ padding-right: 82px !important; }
.pr-83{ padding-right: 83px !important; }
.pr-84{ padding-right: 84px !important; }
.pr-85{ padding-right: 85px !important; }
.pr-86{ padding-right: 86px !important; }
.pr-87{ padding-right: 87px !important; }
.pr-88{ padding-right: 88px !important; }
.pr-89{ padding-right: 89px !important; }
.pr-90{ padding-right: 90px !important; }
.pr-91{ padding-right: 91px !important; }
.pr-92{ padding-right: 92px !important; }
.pr-93{ padding-right: 93px !important; }
.pr-94{ padding-right: 94px !important; }
.pr-95{ padding-right: 95px !important; }
.pr-96{ padding-right: 96px !important; }
.pr-97{ padding-right: 97px !important; }
.pr-98{ padding-right: 98px !important; }
.pr-99{ padding-right: 99px !important; }
.pr-100{ padding-right: 100px !important; }

.pr-5p{ padding-right: 5% !important; }
.pr-10p{ padding-right: 10% !important; }
.pr-15p{ padding-right: 15% !important; }
.pr-20p{ padding-right: 20% !important; }
.pr-25p{ padding-right: 25% !important; }
.pr-30p{ padding-right: 30% !important; }
.pr-35p{ padding-right: 35% !important; }
.pr-40p{ padding-right: 40% !important; }
.pr-45p{ padding-right: 45% !important; }
.pr-50p{ padding-right: 50% !important; }
/* PADDING RIGHT */

/* PADDING LEFT */
.pl-1{ padding-left: 1px !important; }
.pl-2{ padding-left: 2px !important; }
.pl-3{ padding-left: 3px !important; }
.pl-4{ padding-left: 4px !important; }
.pl-5{ padding-left: 5px !important; }
.pl-6{ padding-left: 6px !important; }
.pl-7{ padding-left: 7px !important; }
.pl-8{ padding-left: 8px !important; }
.pl-9{ padding-left: 9px !important; }
.pl-10{ padding-left: 10px !important; }
.pl-11{ padding-left: 11px !important; }
.pl-12{ padding-left: 12px !important; }
.pl-13{ padding-left: 13px !important; }
.pl-14{ padding-left: 14px !important; }
.pl-15{ padding-left: 15px !important; }
.pl-16{ padding-left: 16px !important; }
.pl-17{ padding-left: 17px !important; }
.pl-18{ padding-left: 18px !important; }
.pl-19{ padding-left: 19px !important; }
.pl-20{ padding-left: 20px !important; }
.pl-21{ padding-left: 21px !important; }
.pl-22{ padding-left: 22px !important; }
.pl-23{ padding-left: 23px !important; }
.pl-24{ padding-left: 24px !important; }
.pl-25{ padding-left: 25px !important; }
.pl-26{ padding-left: 26px !important; }
.pl-27{ padding-left: 27px !important; }
.pl-28{ padding-left: 28px !important; }
.pl-29{ padding-left: 29px !important; }
.pl-30{ padding-left: 30px !important; }
.pl-31{ padding-left: 31px !important; }
.pl-32{ padding-left: 32px !important; }
.pl-33{ padding-left: 33px !important; }
.pl-34{ padding-left: 34px !important; }
.pl-35{ padding-left: 35px !important; }
.pl-36{ padding-left: 36px !important; }
.pl-37{ padding-left: 37px !important; }
.pl-38{ padding-left: 38px !important; }
.pl-39{ padding-left: 39px !important; }
.pl-40{ padding-left: 40px !important; }
.pl-41{ padding-left: 41px !important; }
.pl-42{ padding-left: 42px !important; }
.pl-43{ padding-left: 43px !important; }
.pl-44{ padding-left: 44px !important; }
.pl-45{ padding-left: 45px !important; }
.pl-46{ padding-left: 46px !important; }
.pl-47{ padding-left: 47px !important; }
.pl-48{ padding-left: 48px !important; }
.pl-49{ padding-left: 49px !important; }
.pl-50{ padding-left: 50px !important; }
.pl-51{ padding-left: 51px !important; }
.pl-52{ padding-left: 52px !important; }
.pl-53{ padding-left: 53px !important; }
.pl-54{ padding-left: 54px !important; }
.pl-55{ padding-left: 55px !important; }
.pl-56{ padding-left: 56px !important; }
.pl-57{ padding-left: 57px !important; }
.pl-58{ padding-left: 58px !important; }
.pl-59{ padding-left: 59px !important; }
.pl-60{ padding-left: 60px !important; }
.pl-61{ padding-left: 61px !important; }
.pl-62{ padding-left: 62px !important; }
.pl-63{ padding-left: 63px !important; }
.pl-64{ padding-left: 64px !important; }
.pl-65{ padding-left: 65px !important; }
.pl-66{ padding-left: 66px !important; }
.pl-67{ padding-left: 67px !important; }
.pl-68{ padding-left: 68px !important; }
.pl-69{ padding-left: 69px !important; }
.pl-70{ padding-left: 70px !important; }
.pl-71{ padding-left: 71px !important; }
.pl-72{ padding-left: 72px !important; }
.pl-73{ padding-left: 73px !important; }
.pl-74{ padding-left: 74px !important; }
.pl-75{ padding-left: 75px !important; }
.pl-76{ padding-left: 76px !important; }
.pl-77{ padding-left: 77px !important; }
.pl-78{ padding-left: 78px !important; }
.pl-79{ padding-left: 79px !important; }
.pl-80{ padding-left: 80px !important; }
.pl-81{ padding-left: 81px !important; }
.pl-82{ padding-left: 82px !important; }
.pl-83{ padding-left: 83px !important; }
.pl-84{ padding-left: 84px !important; }
.pl-85{ padding-left: 85px !important; }
.pl-86{ padding-left: 86px !important; }
.pl-87{ padding-left: 87px !important; }
.pl-88{ padding-left: 88px !important; }
.pl-89{ padding-left: 89px !important; }
.pl-90{ padding-left: 90px !important; }
.pl-91{ padding-left: 91px !important; }
.pl-92{ padding-left: 92px !important; }
.pl-93{ padding-left: 93px !important; }
.pl-94{ padding-left: 94px !important; }
.pl-95{ padding-left: 95px !important; }
.pl-96{ padding-left: 96px !important; }
.pl-97{ padding-left: 97px !important; }
.pl-98{ padding-left: 98px !important; }
.pl-99{ padding-left: 99px !important; }
.pl-100{ padding-left: 100px !important; }

.pl-5p{ padding-left: 5% !important; }
.pl-10p{ padding-left: 10% !important; }
.pl-15p{ padding-left: 15% !important; }
.pl-20p{ padding-left: 20% !important; }
.pl-25p{ padding-left: 25% !important; }
.pl-30p{ padding-left: 30% !important; }
.pl-35p{ padding-left: 35% !important; }
.pl-40p{ padding-left: 40% !important; }
.pl-45p{ padding-left: 45% !important; }
.pl-50p{ padding-left: 50% !important; }
/* PADDING LEFT */

/* MARGIN PADDING ZERO */
.m-0{ margin: 0px !important; }
.p-0{ padding: 0px !important; }
/* MARGIN PADDING ZERO */

/* BORDERS */
.border-red{ border: 1px solid red; }
.border-green{ border: 1px solid green; }
.border-blue{ border: 1px solid blue; }
.border-yellow{ border: 1px solid yellow; }
.border-black{ border: 1px solid black; }
.border-white{ border: 1px solid white; }
/* BORDERS */

/* BACKGROUND */
.bg-white{ background: white; }
/* BACKGROUND */

/* FLOAT */
.float-right{
    float: right !important;
}

.float-left{
    float: left !important;
}
/* FLOAT */

/* FULL */
.w-4{ width: 4vw; }
.w-full{ width: 100% !important;}
.h-full{ height: 100% !important;}

.h-100vh{ min-height: 100vh; }
/* FULL */

/* TEXT COLOR */
.text-black{ color: #393d48;}
.text-white{ color: #FFFFFF;}
.text-yellow{ color: #ffde00;}
.text-blue{ color: #0664ef;}
.text-red{ color: #e93a1b;}

.text-black-hover:hover{ color: #393d48;}
.text-white-hover:hover{ color: #FFFFFF;}
.text-yellow-hover:hover{color: #ffde00;}
.text-blue-hover:hover{color: #0664ef;}
/* TEXT COLOR */

/* LINKS */
.no-link-decoration{ text-decoration: none;}
/* LINKS */

/* HOVER */
.hover-content .hover-content--on,
.hover-content:hover .hover-content--off {
  display: none;
  transition: 0.3s;
}

.hover-content:hover .hover-content--on {
  display: inline;
  transition: 0.3s;

}
/* HOVER */

/* CURSOR */
.cursor-pointer{
    cursor: pointer;
}
.cursor-not-allowed{
    cursor: not-allowed;
}
/* CURSOR */

/* ALIGN */
.flex-center-align{
    display: flex;
    justify-content: center;
}

.d-flex{
    display: flex;
}

.justify-content-center{
    justify-content: center;
}

.align-items-center{
    align-items: center;
}

.flex-column{
    flex-direction: column;
}
/* ALIGN */

/* HIDDEN */
.hidden{ display: none !important; }
/* HIDDEN */

/* FONT SIZE */
.ft-11{ font-size: 11px !important;}
.ft-12{ font-size: 12px !important;}
.ft-13{ font-size: 13px !important;}
.ft-14{ font-size: 14px !important;}
.ft-15{ font-size: 15px !important;}
.ft-16{ font-size: 16px !important;}
.ft-18{ font-size: 18px !important;}
.ft-20{ font-size: 20px !important;}
.ft-22{ font-size: 22px !important;}
.ft-24{ font-size: 24px !important;}
/* FONT SIZE */

