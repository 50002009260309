.loading-fallback{
    height: 100vh !important;
    background: #16537E ;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cQdWYQ{
    background-color: #343a4000 !important;
    height: 100px !important;
    border: 1px solid rgba(56, 85, 157, 0.515);
    border-radius: 10px;
    color: #fff !important; 
}

.dmrRFV{
    background-color: #4a95f800 !important;
}

.side-nav-link{
    cursor: pointer;
}

.loading-fallback-private{
    height: 100vh !important;
    /* background:  ; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.application-loader{
    position: absolute;
    z-index: 99999999999999 !important;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.288);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.topnav-navbar{
    position: fixed !important;
}

.application-container{
    margin-top: 10vh !important;
}

.leftside-menu{
    position: fixed !important;
    top: 10vh !important;
}

.content-page{
    margin-top: 4.2vh !important;
    min-height: 85vh !important;
    margin-left: 13vw !important;
}

.nav-link.end-bar-toggle{
    cursor: pointer !important;
}

.dropdown-item.notify-item{
   cursor: pointer !important;
}

.side-nav .menu-arrow:before{
   content: "\F142" !important;
}

.side-nav .side-nav-item>div[aria-expanded=true]>span.menu-arrow {
   transform: rotate(90deg) !important;
}

.loading-overlay{
    z-index: 99999999999;
    position: fixed;
    top: 0 ;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-overlay.darkTheme{
    background-color: rgba(255, 255, 255, 0.116);
}

.loading-overlay.lightTheme{
    background-color: rgba(0, 0, 0, 0.116) !important;
}

.table-spinner-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 5% !important;
    margin-bottom: 5% !important;
}

.pagination.react-bootstrap-table-page-btns-ul{
    float:right !important;
}

.search-label{
    float: right;
}

.points-card{
    width: 100%;
    border: 1px solid rgba(152,166,173,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    min-height: 40px;
}

.points-title{
    text-align: center;
}

.close-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.formDarkBorder{
    border: 1px solid #ffffff2a!important;

    border-radius: .25rem!important;
    padding: 0.5rem!important;

}

.formLightBorder{
    border: 1px solid #393e442a!important;

    border-radius: .25rem!important;
    padding: 0.5rem!important;
}

.table-channel-name{
    max-width: 18ch !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.search_input{
    min-width: 15vw !important;
}

.search_select{
    min-width: 10vw !important;
 
}

.bg-d{
    background: #404954 !important;
}



/* @media (max-width: 1700px){
    .content-page{
            margin-left: 17vw !important;

    }
} */

@media (max-width: 1700px){
    .content-page{
            margin-left: 17vw !important;

    }
}

@media (max-width: 1500px){
    .content-page{
        margin-left: 19vw !important;

    }
}


@media (max-width: 1400px){
    .content-page{
        margin-left: 20vw !important;

    }
}


@media (max-width: 1300px){
    .content-page{
        margin-left: 21vw !important;

    }
}

@media (max-width: 1200px){
    .content-page{
        margin-left: 22vw !important;

    }
}

@media (max-width: 1100px){
    .content-page{
        margin-left: 23vw !important;

    }
}


@media (max-width: 1000px){
    .content-page{
        margin-left: 26vw !important;

    }
}

@media (max-width: 900px){
    .content-page{
        margin-left: 27vw !important;

    }
}

@media (max-width: 800px){
    .content-page{
        margin-left: 30vw !important;

    }
}



select.pagination-select {
    width: 85px;
}

.new-line-closing {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.new-line-second {
    display: flex;
}
.margin-into {
    padding: 0px 4px;
}

.react-modal-overlay {
    background: rgba(0,0,0, 0.5);

    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;

    display:flex;
    align-items:center;
    justify-content:center;

}
.react-modal-content {
    background: #efefee;

    width: 100%;
    max-width: 500px;
    border-radius: 4px;
    position: relative;
    padding: 3rem;
}

.file_box {
    width: 100%;
    /* background: #e7e7e7; */
    border: 2px dashed #b9b9b9;
    height: 135px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.textarea_box {
    width: 100%;
    margin: 10px 0;
    background-color: transparent;
}
/* .file_input{
    position: absolute;
    opacity: 0;
    height: 40%;
} */

input#reviewFile {
    position: absolute;
    height: 35%;
    width: 79%;
    opacity: 0;
    cursor: pointer;
}

.linkUrl-review{
    font-size: 12px;
}

.btn-revision {
    border: 0;
    font-size: 20px;
}
.icon-file-open-revision {
    font-size: 23px;
}
.react-modal-overlay_revision {
    background: rgba(0,0,0, 0.5);

    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;

    display:flex;
    align-items:center;
    justify-content:center;
    z-index: 9999;
}
.react-modal-content_revision {
    background: #efefee;

    width: 100%;
    max-width:95%;
    height: 90vh;
    border-radius: 4px;
    position: relative;
    padding: 1rem;
    /* z-index: 99999999999; */
    overflow: hidden;
}
.table>:not(caption)>*>* {
    padding: 7px !important;
    font-size: 12px;
}
.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-100px);
  }

  .statusRevision{
      background-color: transparent;
      border: none;
      outline: none;
  }

  .swal-overlay--show-modal .swal-modal{
      z-index: 999999999 !important;
  }
  .file_box.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .graphics-filter {
      padding: 20px 0;
  }

  .btn-date-search {
    display: flex;
    flex-direction: inherit;
    align-items: end;
  }


  span.gp_rows {
    white-space: normal !important;
  }

  .invalid-react-select .css-1s2u09g-control{
      border:1px solid #fa5c7c;
      border-radius: 5px;
  }

  input {
    border: 1px solid #DEE2E6;
    padding: 0.5rem;
    /* width: 300px; */
  }
  
  .no-suggestions {
    color: #DEE2E6;
    padding: 0.5rem;
  }

  .print_table {
      background: #edebe0;
      width: 80%;
  }

  .print_table td{
    border: 1px solid rgb(84, 84, 84);
  }
  
.print_table_values thead tr th{
    background: #16537E !important;
    color: #fff !important;
    font-weight: bold !important;
    /* height: 30px; */
    display: flex;
    border: 1px solid red;
    justify-content: center;
    align-items: center;
}

.f-text{
    font-size: 9pt;
    font-weight: bold;
    text-align: justify;
}

.table-head{
    background: #16537E !important;
    text-align: center;
    color: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.307);
    border-top: 1px solid rgba(0, 0, 0, 0.307);
    border-left: 1px solid rgba(0, 0, 0, 0.307);
    text-transform: uppercase;

}

.print_table_values {
    width: 80%;
}
.print_table_values tbody tr td{
    border: 1px solid rgba(0, 0, 0, 0.307);
    text-align: center;
}

.align-text-left{
    text-align:left !important;
}
  
  .suggestions {
    border: 1px solid #DEE2E6;
    border-radius: 5px !important;

    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    z-index: 5;
    width: 94.9%;
    /* width: calc(300px + 1rem); */
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  

  .suggestions li:first-child {
    border-top: 1px solid #DEE2E6 !important;
  }

  .suggestions li:not(:last-of-type) {
    /* border-bottom: 1px solid #DEE2E6; */
    border-bottom: 1px solid #DEE2E6;
  }

  .suggestions li:first-child {

    /* border-radius: 5px !important; */
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;

  }

  
  .suggestions li:last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }